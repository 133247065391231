<template>
  <PublicLayout>
    <div
      class="container"
      :class="device + '-container'"
    >
      <img
        v-if="!mq.phone"
        src="@/assets/img_intro.png"
        class="img-intro"
        alt=""
      >
      <h1 v-t="'Partners.title'" />
      <p v-t="'Partners.line1'" />
      <p v-t="'Partners.line2'" />

      <div class="section">
        <div class="title">
          <h3 v-t="'Partners.academicPartners'" />
        </div>

        <div class="partner-list">
          <Partner
            v-for="(parner, key) in academicsPartners"
            :key="key"
            :hover="true"
            class="partner"
            :class="device + '-partner'"
            :img="parner.img"
            :link="parner.link"
            :alt="parner.name"
          />
        </div>
      </div>

      <div class="section">
        <div class="title">
          <h3 v-t="'Partners.institutionalPartners'" />
        </div>

        <div class="partner-list">
          <Partner
            v-for="(parner, key) in institutionalsPartners"
            :key="key"
            :hover="true"
            class="partner"
            :class="device + '-partner'"
            :img="parner.img"
            :link="parner.link"
            :alt="parner.name"
          />
        </div>
      </div>

      <div
        class="row"
        :class="device + '-row'"
      >
        <div class="column">
          <div class="title">
            <h3 v-t="'Partners.francePartners'" />
          </div>

          <div class="partner-list">
            <Partner
              v-for="(parner, key) in francePartners"
              :key="key"
              :hover="true"
              :name="parner.name"
              class="partner"
              :class="device + '-partner'"
              :img="parner.img"
              :link="parner.link"
              :alt="parner.name"
            />
          </div>
        </div>
        <div class="column">
          <div class="title">
            <h3 v-t="'Partners.suissePartners'" />
          </div>

          <div class="partner-list">
            <Partner
              v-for="(parner, key) in suissePartners"
              :key="key"
              :hover="true"
              :name="parner.name"
              class="partner"
              :class="device + '-partner'"
              :img="parner.img"
              :link="parner.link"
              :alt="parner.name"
            />
          </div>
        </div>
      </div>
    </div>
  </PublicLayout>
</template>

<script>
import PublicLayout from '@/router/layouts/PublicLayout.vue'
import Partner from '@components/Partners/Partner'
import partnersConstant from '@/constants/partners'

export default {
  components: {
    Partner,
    PublicLayout
  },
  inject: ['mq'],
  data () {
    return {
      academicsPartners: partnersConstant.academicsPartners,
      institutionalsPartners: partnersConstant.institutionalsPartners,
      francePartners: partnersConstant.francePartners,
      suissePartners: partnersConstant.suissePartners
    }
  },
  computed: {
    device () {
      if (this.mq.phone) {
        return 'phone'
      } else {
        return 'desktop'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@design";

.container {
  position: relative;
  padding: 40px 15% 0 15%;
  height: 100%;
  overflow-y: auto;
  background-color: $public-bg;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $color-light-text;

  .img-intro {
    position: absolute;
    top: -25px;
    left: -150px;
    height: 800px;
    z-index: 0;
  }

  h1{
    font-size: 40px;
    margin-bottom: 36px;
    line-height: 40px;
    position: relative;
    text-align: center;
  }

  p {
    font-size: 18px;
    margin-top: 5px;
    position: relative;
    text-align: center;
  }

  .section {
    margin-top: 80px;
    width: 100%;
    max-width: 832px;
    position: relative;
    background-color: $color-body-bg;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    text-align: center;

    .title {
      position: absolute;
      top: -35px;
      height: 52px;
      width: 100%;
      max-width: 404px;
      border-radius: 6px 6px 0 0;
      background-color: $color-menu-bg;
      box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
      font-weight: bold;
      color: $color-cadyco-light-text;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .partner-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;

    .partner {
      margin: 30px;
    }

    .phone-partner {
      margin: 5px;
    }
  }

  .row {
    margin-bottom: 80px;
    width: 100%;
    max-width: 832px;
    display: flex;
    justify-content: space-between;

    .column {
      margin-top: 80px;
      position: relative;
      width: 49%;
      min-width: 240px;
      background-color: $color-body-bg;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 6px;

      .title {
        position: absolute;
        top: -35px;
        height: 52px;
        width: 100%;
        max-width: 326px;
        border-radius: 6px 6px 0 0;
        background-color: $color-menu-bg;
        box-shadow: 0 0 30px 0 rgba(0,0,0,0.1);
        font-weight: bold;
        color: $color-cadyco-light-text;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .partner-list {
        flex-direction: column;
      }
    }
  }

  .phone-row {
    flex-direction: column;

    .column{
      width: 100%;
    }
  }
}

.phone-container {
  padding: 30px 5% 0 5%;

  h1{
    font-size: 2em;
    line-height: 30px;
    margin-bottom: 36px;
    position: relative;
  }

  p {
    width: 100%;
    font-size: 1.125em;
    margin-top: 0;
    line-height: 22px;
    position: relative;
    text-align: left;
  }
}

</style>
